export type Document = {
  documentId: string
  documentLabel: string
  documentType: string
  documentName: string
  documentSize: number
  tags: string[]
  rank: number
}

export const documentsSubscriptionPayload = (documents: Document[]) => {
  const filterCriteria = [
    'Fiche Info Conseil',
    'Mandat SEPA',
    "Demande d'adhésion",
    'Conditions Générales',
    'Information produit',
    'Exemples de remboursement',
  ]

  const filteredDocuments = documents
    .filter((doc) => {
      return filterCriteria.some((criteria) =>
        doc.documentLabel.toLowerCase().includes(criteria.toLowerCase())
      )
    })
    .sort((a, b) => a.rank - b.rank)

  return filteredDocuments
}

export const formattedLabel = (label: string) => {
  let newLabel = ''

  if (label.toLocaleLowerCase().includes('fiche info conseil')) {
    newLabel = 'Fiche Info Conseil'
  } else if (label.toLocaleLowerCase().includes('mandat sepa')) {
    newLabel = 'Mandat SEPA'
  } else if (label.toLocaleLowerCase().includes("demande d'adhésion")) {
    newLabel = "Demande d'adhésion"
  } else if (label.toLocaleLowerCase().includes('conditions générales')) {
    newLabel = 'Conditions générales'
  } else if (label.toLocaleLowerCase().includes('information produit')) {
    newLabel = 'Information produit'
  } else if (label.toLocaleLowerCase().includes('exemples de remboursement')) {
    newLabel = 'Exemples de remboursements'
  }

  return newLabel
}
