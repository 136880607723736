import { render, staticRenderFns } from "./_orderId.vue?vue&type=template&id=70b5f095"
import script from "./_orderId.vue?vue&type=script&setup=true&lang=ts"
export * from "./_orderId.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./_orderId.vue?vue&type=style&index=0&id=70b5f095&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FoundationsUiIcon: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-icon/ui-icon.vue').default,AtomsCtaLink: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-link/cta-link.vue').default,MoleculesCrossSellCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/cross-sell-card/cross-sell-card.vue').default})
